<template>
  <div class="static-page faq-page">

    <header-clean :header="$t('faq.title')"/>

    <v-container class="padding-tb-50">

      <v-expansion-panels multiple role="list">
        <v-expansion-panel class="v-expansion-panel--active" v-for="q in $t('faq.questions')" :key="q.q" role="listitem">

          <!-- <a :id="qAnchor(q.q)" :ref="qAnchor(q.q)"/> -->
          <v-expansion-panel-header class="font-18 font-600" role="term">
            {{q.q}}
          </v-expansion-panel-header>

          <v-expansion-panel-content role="definition">
            <vue-markdown>{{q.a}}</vue-markdown>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

    </v-container>
  </div>
</template>

<script>
import HeaderClean from '@/app/common/components/header-clean'

export default {
  name: 'Faq',

  metaInfo () {
    return {
      title: 'FAQ',
      meta: [
        {
          property: 'og:title',
          content: this.$t('meta.faq.title'),
          vmid: 'og:title'
        },
        {
          property: 'og:description',
          content: this.$t('meta.faq.description'),
          vmid: 'og:description'
        },
        {
          property: 'description',
          content: this.$t('meta.faq.description'),
          vmid: 'description'
        }
      ]
    }
  },

  components: {
    HeaderClean
  },

  methods: {
    // qAnchor (q) {
    //   return q.replaceAll(' ', '_').replace('?', '')
    // }
  },

  mounted () {
    // const hash = this.$route.hash.replace('#', '')
    // const aref = this.$refs[hash]
    // console.log(this.$refs)
    // console.log(hash)
    // console.log(aref)
  }
}
</script>

<style lang="scss">

  .faq-page {

    .header-clean {
      position: relative;

      > .row {
        z-index: 1;
      }

      &:before {
        content: "FAQ";
        position: absolute;
        width: 100%;
        line-height: 100%;
        font-size: min(30vw, 250px);
        color: var(--v-secondary-lighten1);
        letter-spacing: min(13vw, 100px);
        text-align: center;
        font-family: "M PLUS Rounded 1c", sans-serif;
        font-weight: 600;
        text-indent: -75px;
        opacity: 0.7;
        transform: matrix3d(1,0.05,0,0.0005,0.2,1,0,0.0005,0,0,1,0,0,0,0,1.1);
      }
    }
  }

</style>
