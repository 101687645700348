<template>
  <v-row no-gutters :class="['align-center', 'header-clean', { nobg }]" v-if="header || subheader">
    <v-row no-gutters class="justify-center text-center">
      <div class="padding-sides-20 margin-top-20">
        <h1 class="page-header" v-if="header" v-html="header"/>
        <h2 class="page-subheader" v-if="subheader" v-html="subheader"/>
        <slot/>
      </div>
    </v-row>
  </v-row>
</template>

<script>

export default {

  name: 'HeaderClean',

  props: {
    header: String,
    subheader: String,
    nobg: Boolean
  }
}

</script>

<style lang="scss" scoped>

  .header-clean {

    &:not(.nobg) {
      background-color: var(--v-secondary-base);
      box-shadow: 0 -10px 30px 6px var(--v-secondary-base);
    }

    @include xs-only {
      height: 250px;
    }

    @include sm-and-up {
      height: 350px;
    }
  }

</style>
