<template>
  <div class="static-page tos-page">
    <HeaderClean image="/app_images/about-header-1.webp" header="תנאי השירות"/>

    <Spacer height="50px"/>

    <v-container>
      <section>
        <h4>תנאי שימוש</h4>
        <p>
          ברוכים הבאים לאתר האינטרנט של חברת RBNS/ יג שוהם שיווק נדל״ן  (החברה) היא הבעלים והמפעילה של אתר והאפליקציה www.rbns.co.il המספק פלטפורמת מידע ושיווק - מכירה והשכרה של נכסי נדל"ן, המציגה מידע בין היתר באמצעות מאמרים, טפסים, מדריכים ועוד.
          <br>
          הגלישה והשימוש באתר כפופה להסכמתך המקדמית לתנאי השימוש כפי שיפורטו להלן בתקנון זה ("תקנון השימוש") ובמדיניות הפרטיות ("מדיניות הפרטיות"). השימוש באתר מעיד על הסכמתך לתנאים אלה ולכן הנך מתבקש לקרוא תנאי שימוש המובאים להלן בקפידה. תנאי השימוש באתר מנוסחים בלשון זכר מטעמי נוחות בלבד, והם מתייחסים, כמובן, אף לנשים.
        </p>
      </section>

      <section>
        <h4>הקדמה</h4>
        <p>
          כל המבצע פעולה באתר מצהיר כי קרא והבין את תקנון האתר וכי לא תהיה לו ו/או למי מטעמו כל טענה ו/או תביעה כנגד החברה ו/או מפעליו ו/או תביעה כנגד החברה ו/או מפעיליו ו/או מי מטעמם כל טענה ו/או תביעה כנגד החברה ו/או מפעליו ו/או מי מטעמם מלבד טענות הנוגעות בהפרת התחייבות בעלי האתר ו/או מפעליו על פי תקנון ותנאי השימוש המובאים להלן.
          <br>
          אתה מאשר בזאת כי הנך בעל כשרות משפטית ובגיל מספק על פי חוק ליצור חוזה מחייבת עליך להיות בן 18 לפחות כדי שתהיה כשיר להשתמש באתר ו/או בשירות.
        </p>
      </section>

      <section>
        <h4>השימוש באתר</h4>
        <Spacer height="20px"/>
        <h5>1. הגדרות</h5>
        <p>
          1.1 "המשתמש"/ "המשתמשים" - משתמשי האתר הרשומים ו/או משתמשי האתר שאינם רשומים ו/או מנויים על סוגיהם ו/או מבקרי האתר, בין באופן חד פעמי ובין באופן קבוע.
        </p>
        <p>
          1.2 "מנוי" - משתמש אשר נרשם לקבלת שירותי האתר ומחזיק בשם משתמש וסיסמה ייחודי עבורו.
        </p>
        <p>
          1.3 "מנוי חינמי" ו/או "ניסיוני" - מנוי לתקופה קצובה אשר תקבע על ידי החברה, המאפשר להשתמש בחלקים מסוימים במערכת לצורך בחינת התאמת המערכת לשימוש המשתמש.
        </p>
      </section>

      <section>
        <h5>2. הסכמה לתנאי השימוש</h5>
        <p>
          2.1 בעצם ההרשמה ו/או יצירת חשבון ו/או הכניסה ו/או הביקור באתר ו/או השימוש באתר ו/או בקשר לאתר בכל מדיה אחרת הינך נותן את הסכמתך ואישורך כי קראת והבנת את תנאי השימוש שלהלן לרבות הצהרת הפרטיות העולה מתנאי שימוש אלה אשר מופיעה בהמשך ו/או בנפרד באתר זה. במידה ואינך מסכים לתנאי השימוש אנא הימנע משימוש בשירותים של החברה ו/או באתר.
        </p>
      </section>

      <section>
        <h5>3. פעילות האתר</h5>
        <p>
          3.1 החברה אינה מתחייבת כי הכלים הטכנולוגיים באתר ו/או המידע יפעל באופן שוטף ותדיר. החברה תעשה את מירב המאמצים לתקן תקלות מערכת. יובהר, כי חלק מהמידע באתר מופעל ו/או תלוי על ידי גורמים חיצוניים של מפעילי האתר אין שליטה עליו.
        </p>
        <p>
          3.2 לחברה תהיה הזכות, בכל עת, לשנות ו/או להפסיק כל מאפיין ו/או תכונה ו/או שימוש של האתר ו/או השירות, לרבות תוכן, שעות פעילות ו/או ציוד ו/או תנאים הנדרשים לצורך גישה ושימוש באתר ו/או בחלק מהאתר. החברה רשאית להפסיק הפצתו של כל מידע, לשנות ו/או להפסיק כל שיטת העברת מידע רשאית לשנות את מהירויות העברת המידע או כל מאפיין אותות ו/או מאפיים שידור אחרים.
        </p>
        <p>
          3.3 החברה שומרת לעצמה את הזכות לפעול ולשנות מעת לעת את אופן השימוש באת, התוכניות המוצעות למשתמשי האתר ו/או השירות המוצע בו.
        </p>
      </section>

      <section>
        <h5>4. טיב השירות</h5>
        <p>
          4.1 החברה מעמידה לרשות המשתמשים את האתר להצגת מידע לנוחיותכם ושימושם ואין לראות את החברה אחראית על המידע או כמי שחברה באחריות כלשהי בגין המידע.
        </p>
        <p>
          4.2 המערכת מבוססת WEB ותלויה בחיבור אינטרנט רציף ואיכותי. המשתמש מכיר בכך שהשימוש באינטרנט כרוך לעיתים בהפרעות, שאינן בשליטת החברה ועל כן החברה לא תהיה אחראית לכל שיבוש ו/או לכל אובדן מידע בזמן העברת מידע על גבי האינטרנט. ייתכן שהגישה לאתר ו/או לחלקיו ו/או לשירות תופר, תושעה ו/או תופסק, באופן זמני או לצמיתות. המשתמש מוותר על טענה ואו דרישה ו/או תשלום ו/'או הפסד שיגרמו לו עקב האמור לעיל ובכלל כתוצאה משימוש באתר.
        </p>
        <p>
          4.3 דרישות ו/או מדיניות החברה יכולים להשתנות בהתאם לאופי הפעילות המתבצעת באתר. תיתכן התקשרות המשך בין החברה למשתמשים לאחר מילוי פרטים בטופס האתר.
        </p>
        <p>
          4.4 כל שימוש באתר הינו באחריות המשתמש בלבד. המשתמש מצהיר כי ידוע לו שהחברה אינה ממליצה ו/או מביעה דעה באמצעות התוכן שמופיע באתר לרבות המידע ו/או השירות. אין להסתמך ו/או לקחת החלטות על בסיס המידע. מלוא האחריות הבלעדית מוטלת על המשתמש והוא מודע על כל ההשלכות האפשריות כתוצאה הסתמכות ו/או נזק ישיר ו/או עקיף.
        </p>
      </section>

      <section>
        <h5>5. תוכן האתר</h5>
        <p>
          5.1 אין לחברה כל אחריות לתוכן המופיע באתר אשר מוזן ו/או מגיע על ידי ו/או מידי גורמים חיצוניים לרבות צדדים שלישיים.
        </p>
        <p>
          5.2 החברה אינה אחראית לדיוק, לנכונות, לשמות והשימושיות של תוכן ו/או מידע האתר. בשום נסיבות, החברה לא תהיה אחראית וכן לא תחויב בגין כל נזק ו/או הפסד שנגרמו בשל הסתמכות משתמש ו/או כל צד אחר על מידע שהושג דרך האתר ו/או סופק על ידי החברה הינם באחריות המשתמש בלבד. ככל שזיהיתם פערי מידע ו/או שגיאות הינכם מוזמנים לדווח באזור יצירת קשר באתר.
        </p>
        <p>
          5.3 ככל שהנכם רואים כי תוכן האתר פוגע בזכויות צד שלישי, תוכלו לדווח על כך באזור צור קשר באתר. שימו לב כי פרטיכם פנייתכם יועברו לצדדים שלישיים כדי לבחון את טענתכם.
        </p>
      </section>

      <section>
        <h5>5. השימוש באתר</h5>
        <p>
          6.1 חל איסור לעשות שימוש לרעה באתר ובתוכן האתר לרבות למטרות שאינן חוקיות או שיש בהן כדי לגרום לנזק, הטעיה, לעודד, לשדל, להמריץ ולפעות בניגוד לחוק, פגיעה בזכויות קניין רוחני של צדדים שלישיים ובכלל פגיעה בפרטיות של צד כלשהו, להטיל אחריות על האתר ו/או לפגוע במוניטין האתר ומפעליו.
        </p>
        <p>
          6.2 יצירת קשר:
          <br>
          מייל : contact@rbns.co.il, טלפון: 0774110137.
          <br>
          החברה תעשה מאמץ לחזור לפניות תוך זמן סביר.
        </p>
      </section>

      <section>
        <h5>7. פרטיות</h5>
        <p>
          7.1 משתמשי האתר מצהירים כי ידוע להם כי החברה אוספת מידע סטטיסטי אודות פעילות המשתמשים באתר, בין היתר באמצעות שימוש בטכנולוגיית cookies  וכלים אנליטיים אחרים למטרות שיווקיות, עסקיות ועוד על מנת להתאים את האתר להעדפות המשתמש.
        </p>
      </section>

      <section>
        <h5>8. זכויות הקניין של החברה</h5>
        <p>
          8.1 כל זכויות היוצרים והקניין הרוחני באתר - לרבות בשם וסימני המסחר <b>rbns.co.il</b> , בעיצוב האתר, בתכנים המתפרסמים בו על-ידי <b>rbns.co.il</b> או מטעמה ובכל תוכנה, יישום, קוד מחשב, קובץ גרפי, טקסט וכל חומר אחר הכלולים בו - הינן של <b>rbns.co.il</b>. אין להעתיק, להפיץ, להציג בפומבי, לשנות, לעבד, ליצור יצירות נגזרות, למכור, להשכיר או למסור לצד שלישי כל חלק מן הנ"ל בכל אמצעי או דרך, בלא קבלת הסכמתה של <b>rbns.co.il</b> בכתב ומראש.
        </p>
        <p>
          8.2 כל הפרה של האמור בסעיף זה, עלולה לגרום לתוצאה של הפרת זכויות יוצרים, זכויות סימני מסחר ו/או זכויות קניין רוחני אחרות, באופן שעלול להשית על המשתמשים עונשים ו/או קנסות פליליים ו/או אזרחיים.
        </p>
      </section>

      <section>
        <h5>9. שינוי תנאי השימוש ומדיניות הפרטיות</h5>
        <p>
          9.1 מידע אישי שנמסר לחברה באמצעות אתר זה, יישמר בהתאם להוראות חוק הגנת הפרטיות, התשמ"א 1981. במידה ותתגלה סתירה או אי-התאמה בין חומר המתפרסם באתר לבין חומר מופיע בפרסומים הרשמיים בכתב של החברה, רק החומר המופיע בפרסומים הרשמיים כאמור ייחשב כנכון.
        </p>
        <p>
          9.2 החברה תוכל לשנות מעת לעת את מבנה האתר הכולל את מראם, היקפם, תוכנם וזמינותם של השירותים ו/או המוצרים המוצעים באתר וכל היבט אחר הכרוך בהם- והכל ללא צורך להודיע על כך מראש.
        </p>
        <p>
          9.3 מבלי לפגוע באמור לעיל, החברה אינה אחראית לכל אובדן של מידע מכל סוג, שייגרם מכל סיבה שהיא. לא תשמע כל טענה נגד החברה שמקורה בהסתמכות כלשהי על מידע שתתפרסם בזמן מסוים באתר.
        </p>
      </section>

      <section>
        <h5>10. דין ומקום שיפוט</h5>
        <p>
          10.1 הדין החל על הפעולות באתר ועל תנאי שימוש אלה, הוא הדין הישראלי בלבד. סמכות ייחודית ובלעדית לדון בכל תביעה ו/או בקשה העשויה להיות מוגשת בקשר לתנאי שימוש אלו נתונה לבית המשפט המוסמך לפני עניין.
        </p>
        <p>
          10.2 חוסר התקופות של אחד או יותר מתנאי השימוש אלו לא תשפיע על תקופות תנאי שימוש אלו בכלל.
        </p>
      </section>
    </v-container>
    <Spacer height="50px"/>
  </div>
</template>

<script>
import HeaderClean from '@/app/common/components/header-clean'

export default {
  name: 'Tos',

  metaInfo () {
    return {
      title: this.$t('meta.tos.title')
    }
  },

  components: {
    HeaderClean
  }
}
</script>

<style lang="scss">

  .tos-page {

    a {
      color: var(--v-accent-darken3);
    }
  }
</style>
